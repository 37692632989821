import _ from 'lodash';
import { Notification } from './notification';
import Store from '../store';
import { SurveyWrapper } from '@/models/survey';
import { Submission, SubmissionResult } from '@/models/submission';
import { FirebaseService } from '@/services/firebase';
import { LoggingService } from '@/services/logging';
import {v4 as uuidv4} from 'uuid';

export const Submitter = {
  async trySubmitUnsubmitted(notifyOnFail: boolean) {
    const groupedByCount = getUnsubmittedSurveysGroupedByCount();
    if (_.isEmpty(groupedByCount)) return;
    for (let code in groupedByCount) {
      const countSurveys = groupedByCount[code].map((s) => s.surveyWrapper);
      const countOrganizationID = groupedByCount[code][0].organizationID;
      await this.sendRequest(
        countSurveys,
        code,
        countOrganizationID
      );
      Notification.methods.send(true, 'Upload Successful', '');
      Store.commit('removeSubmitted', countSurveys);
    }
  },
  async sendRequest(
    surveys: SurveyWrapper[],
    countID: string,
    organizationID: string
  ): Promise<SubmissionResult> {
    let result;
    do {
      result = await FirebaseService.submitSurveys(
        surveys,
        countID,
        organizationID
      );
      LoggingService.Log(result);
    } while (result === SubmissionResult.DocFull);
    return result;
  },
};

const getUnsubmittedSurveysGroupedByCount = () => {
  const unsubmittedSurveys = Store.getters.unsubmittedSurveys;
  if (unsubmittedSurveys.length === 0) return {};
  const groupedByCount = _.groupBy(unsubmittedSurveys, (s: Submission) => {
    return s.countID;
  });
  return groupedByCount;
};
