
import Vue from 'vue';
import { Survey, FormType } from '@/models/survey';
import { Submission } from '@/models/submission';
import { Submitter } from '@/services/submitter';
import SurveyHistoryItem from '@/components/SurveyHistoryItem.vue';
import {Notification} from '../services/notification';
interface forDisplay {
  time: string;
  type: FormType;
  uploaded: boolean;
}
export default Vue.extend({
  name: 'SurveyHistory',
  components: {
    SurveyHistoryItem,
  },
  computed: {
    surveys() {
      const isFullView = this.$router.currentRoute.name === 'submissions';
      const sorted: Submission[] = this.$store.getters.surveys.sort(
        (a: Submission, b: Submission) =>
          b.surveyWrapper.date - a.surveyWrapper.date
      );
      return isFullView ? sorted : sorted.slice(0, 3);
    },
    unsubmittedExist() {
      return this.$store.getters.unsubmittedSurveys.length > 0;
    },
    isFullView(): boolean {
      return this.$router.currentRoute.name === 'submissions';
    },
  },
  methods: {
    async exportSurveys() {
      if (!this.$store.getters.unsubmittedSurveys || !Array.isArray(this.$store.getters.unsubmittedSurveys) || this.$store.getters.unsubmittedSurveys.length === 0) {
         Notification.methods.send(true, 'Nothing to copy', '')
        return
      }
      await navigator.clipboard.writeText(JSON.stringify(this.$store.getters.unsubmittedSurveys.map((us: any) => us.surveyWrapper)))
       Notification.methods.send(true, 'Text copied to clipboard', '')
    },
    seeAll() {
      this.$router.push({ path: '/submissions' });
    },
    reupload() {
      Submitter.trySubmitUnsubmitted(true);
    },
  },
});
