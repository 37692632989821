
    import Vue from 'vue';
    import {Survey, FormType, SurveyWrapper} from '@/models/survey';
    import {Submission} from '@/models/submission';

    export default Vue.extend({
        name: "SurveyHistoryItem",
        props: ['item', 'index'],
        computed: {
            time() {
                // Of the form '5 minutes' or '10 hours'
                const timePart = timeSince(this.item.surveyWrapper.date);
                return `Completed ${timePart} ago.`
            }
        },
        methods: {
            isObservation(survey: SurveyWrapper) {
                return survey.type === FormType.PITCountObservation;
            },
            isUploaded(submission: Submission) {
                return submission.uploaded;
            },
        }
    });

    const timeSince = (date: number) => {
        let seconds = Math.floor((Date.now() - date) / 1000);

        let interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " years";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " months";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + " days";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " hours";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }
